import './App.css';
import DisplayInfo from './components/DisplayInfo.js'
import { useEffect, useState } from 'react';
import SearchCharacter from './components/SearchCharacter.js'
import axios from 'axios';

function App() {

  const [characterData, setCharacterData] = useState(null)
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {

    async function fetchCharacter() {
      const characterPages = [1, 2, 3, 4, 5, 6, 7, 8, 9]
      const speciesPages = [1, 2, 3, 4]
      const planetPages = [1, 2, 3, 4, 5, 6]

      // let tempCharacters = characterPages.map( async (page) => {
      //   const results = await axios.get(`https://swapi.dev/api/people/?page=${page}`);
      //   return results.data.results;
      // })

      // let tempPlanets = planetPages.map(async (page) => {
      //   const results = await axios.get(`https://swapi.dev/api/planets/?page=${page}`);
      //   return results.data.results;
      // })

      // let tempSpecies = speciesPages.map(async (page) => {
      //   const results = await axios.get(`https://swapi.dev/api/species/?page=${page}`)
      //   return results.data.results;
      // })

      // tempCharacters = await Promise.all(tempCharacters).flat();
      // tempPlanets = await Promise.all(tempPlanets).flat();
      // tempSpecies = await Promise.all(tempSpecies).flat();

      let tempCharacters = Promise.all(characterPages.map(async (page) => {
        const results = await axios.get(`https://swapi.dev/api/people/?page=${page}`)
        return results.data.results
      }))


      let tempPlanets = Promise.all(planetPages.map(async (page) => {
        const results = await axios.get(`https://swapi.dev/api/planets/?page=${page}`)
        return results.data.results
      }))


      let tempSpecies = Promise.all(speciesPages.map(async (page) => {
        const results = await axios.get(`https://swapi.dev/api/species/?page=${page}`)
        return results.data.results
      }))

      tempPlanets = await tempPlanets
      tempSpecies = await tempSpecies
      tempCharacters = await tempCharacters
      tempCharacters = tempCharacters.flat()

      tempPlanets = arrayToDict(tempPlanets.flat())
      tempSpecies = arrayToDict(tempSpecies.flat())

      for (const char of tempCharacters) {
        char.homeworld = tempPlanets[char.homeworld]
        char.species = char.species.length === 0 ? 'Human' : tempSpecies[char.species[0]]
      }

      setCharacterData(tempCharacters)
    }

    fetchCharacter();

  }, [])

  function arrayToDict(array) {
    let obj = {}
    for (let item of array) {
      obj[item['url']] = item['name']
    }
    return obj
  }

  return (
    <div className='App'>
      <SearchCharacter
        setSearchValue={setSearchValue}
      />
      <DisplayInfo
        characterData={characterData}
        searchValue={searchValue}
      />
    </div>
  );
}

export default App;
